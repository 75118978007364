<template>
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ul class="footermenu">
                        <li><router-link to="/children-protection">Protect children</router-link></li>
                        <li><router-link to="/terms">Terms & Conditions</router-link></li>
                        <li><router-link to="/privacy">Privacy Policy</router-link></li>
                        <li><router-link to="/pricing">Pricing</router-link></li>
                        <li><router-link to="/cookies">Cookie Policy</router-link></li>
                        <li><router-link to="/contact">Contact</router-link></li>
                    </ul>
                    <div style="width: 160px; margin: 0 auto 22px auto; display: flex; gap: 8px; align-items: center;">
                        <label for="default-currency" style="margin: 0;">Default currency: </label>
                        <div class="select-container">
                            <select id="default-currency" data-currency-set v-model="currentCurrency"
                            @change="$emit('update:currency', $event.target.value); ">
                                <option v-for="sp in instanceInfo?.subscriptionPlans" :value="sp.currency" :key="sp.currency">{{ sp.currency }}</option>
                                </select>
                        </div>
                    </div>
                    <p class="copyright">
                        Copyright &copy; {{ new Date().getFullYear() }}. All rights reserved.<br /><br />
                        <span class="rtl">{{ companyName }}</span> , {{ instanceInfo?.companyAddress1 }}, {{
                            instanceInfo?.companyAddress2 }}
                    </p>
                    <div class="payment-logos">
                        <img src="/theme/001/images/payment/payment_logos.png" alt="Payment method logo">
                    </div>
                    <p class="copyright" style="font-size:10px!important;">
                        <br />
                        The minimum age for participation on this Website is 18 years. The Website is optimized for desktop,
                        mobile, and tablets. The Website offers a digital chat service for men and women looking for a fun,
                        flirty, and exciting connections in a secure digital environment. Before using the Website, please
                        read the <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy policy</a>. If you
                        want to learn more about pricing, please <a href="/pricing">click here</a>.
                    </p>
                </div>
            </div><!--/row-->
        </div><!--/container-->
    </div><!--/footertop-->
</template>

<script>
import { ref, toRef, watch } from 'vue';

export default {
    name: 'Footer',
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
    setup(props) {
    const subscriptionPlan = toRef(props.instanceInfo, 'subscriptionPlan');
    const currentCurrency = ref(subscriptionPlan.value.currency);

    watch(
      () => props.instanceInfo,
      async () => {
        currentCurrency.value = props.instanceInfo.subscriptionPlan.currency;
      },
      { immediate: true, deep: true }
    );

    return { currentCurrency };
  },
};
</script>
