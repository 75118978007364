<template>
    <div class="card mobile-only">
        <div class="card-header" role="tab" id="headingFilter">
            <div class="toggle">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapseFilter" aria-expanded="false"
                    aria-controls="collapseFilter" style="border-radius:0px!important;">
                    Search
                </a>
            </div>
        </div>

        <div id="collapseFilter" class="collapse" role="tabpanel" aria-labelledby="headingFilter">
            <div class="searchbar">
                <div class="container">
                    <form @submit.prevent="submitSearch">
                        <ul class="searchform-list">
                            <li>
                                <label>Find</label>
                                <div class="select-container">
                                    <select v-model="gender" name="gender">
                                        <option value="">All</option>
                                        <option value="MALE">Men</option>
                                        <option value="FEMALE" selected="selected">Women</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <label>Age</label>
                                <div class="select-container">
                                    <select v-model="ageMin" name="age_min">
                                        <option v-for="n in 82" :value="(n + 17).toString()" :key="n">
                                            {{ n + 17 }}
                                        </option>
                                    </select>
                                </div>
                                <label> &nbsp; -</label>
                                <div class="select-container">
                                    <select v-model="ageMax" name="age_max">
                                        <option v-for="n in 82" :value="(n + 17).toString()" :key="n">
                                            {{ n + 17 }}
                                        </option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <input v-model="onlyWithPhoto" type="checkbox" name="photo" id="checkbox_photo">
                                <label for="checkbox_photo"> Only with photo</label>
                            </li>
                            <li>
                                <input type="submit" value="Search" class="btn">
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="searchbar mobile-hide">
        <div class="container">
            <form @submit.prevent="submitSearch">
                <ul class="searchform-list">
                    <li>
                        <label>Find</label>
                        <div class="select-container">
                            <select v-model="gender" name="gender">
                                <option value="">All</option>
                                <option value="MALE">Men</option>
                                <option value="FEMALE">Women</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <label>Age</label>
                        <div class="select-container">
                            <select v-model="ageMin" name="age_min">
                                <option v-for="n in 82" :value="(n + 17).toString()" :key="n">
                                    {{ n + 17 }}
                                </option>
                            </select>
                        </div>
                        <label> &nbsp; -</label>
                        <div class="select-container">
                            <select v-model="ageMax" name="age_max">
                                <option v-for="n in 82" :value="(n + 17).toString()" :key="n">
                                    {{ n + 17 }}
                                </option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <input v-model="onlyWithPhoto" type="checkbox" name="photo" id="checkbox_photo2">
                        <label for="checkbox_photo2"> Only with photo</label>
                    </li>
                    <li>
                        <input type="submit" value="Search" class="btn">
                    </li>
                </ul>
            </form>
        </div><!--/container-->
    </div><!--/searchbar-->
</template>
    
<script>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { redirectToRoute } from '@/app/router';

export default {
    name: 'MemberSearchBar',
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
    },
    setup() {
        const route = useRoute();
        const gender = ref(route.query.gender ? route.query.gender : '');
        const ageMin = ref(route.query.ageMin ? route.query.ageMin : 18);
        const ageMax = ref(route.query.ageMax ? route.query.ageMax : 99);
        const onlyWithPhoto = ref(route.query.onlyWithPhoto ? route.query.onlyWithPhoto : false);

        const submitSearch = () => {
            redirectToRoute('/members?gender=' + gender.value + '&ageMin=' + ageMin.value + '&ageMax=' + ageMax.value + '&onlyWithPhoto=' + onlyWithPhoto.value);
        };

        watch(
            () => route.query,
            async () => {
                gender.value = route.query.gender ? route.query.gender : '';
                ageMin.value = route.query.ageMin ? route.query.ageMin : 18;
                ageMax.value = route.query.ageMax ? route.query.ageMax : 99;
                onlyWithPhoto.value = route.query.onlyWithPhoto ? route.query.onlyWithPhoto : false;
            },
            { immediate: true, deep: true }
        );

        return {
            gender,
            ageMin,
            ageMax,
            onlyWithPhoto,
            submitSearch
        };
    },
};
</script>
