<template>
<div class="header">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <div class="logo"><router-link to="/"><img :src="instanceInfo?.logo" :alt="instanceInfo?.name + ' logo'"></router-link></div>
            </div>
            <div v-if="displayLoginForm" class="col-md-7">
                <div v-if="isLogged && !isPremium" class="header-login-form">
                    <ul class="pack">
                        <li>
                            <div class="pack-title">Trial</div>
                            <div class="pack-price" style="margin-top:10px;">{{ instanceInfo.subscriptionPlan.initialTariff }} {{ instanceInfo.subscriptionPlan.currency }}</div>
                            <router-link to="/credits/subscription" class="btn">Buy</router-link>
                        </li>
                    </ul>
	            </div>
                <div v-if="!isLogged" class="header-login-form">
                    <form accept-charset="UTF-8" class="form-inline"  @submit.prevent="submitLogin">
                        <span v-if="loginFailed">The details you have entered are incorrect. Please try again.<br/></span>
                        <input v-model="loginUsername" class="form-control" placeholder="Email" id="email" name="email" type="text">
                        <input v-model="loginPassword" class="form-control" placeholder="Password" id="pwd" name="password" type="password">
                        <input type="submit" class="btn btn-default btn-pink" value="Log in">
                    </form>
                </div>
            </div>
        </div><!--/row-->
    </div><!--/container-->
</div><!--/header-->

</template>

<script>
import { ref } from 'vue';
import { loginRequest } from '@/app/api/auth';
import { useUser } from '@/app/use/user';

export default {
    name: 'Header',
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        displayLoginForm: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup() {
        const { isLogged, login, isPremium } = useUser();
        const loginUsername = ref('');
        const loginPassword = ref('');

        const isLoggingIn = ref(false);
        const loginFailed = ref(false);

        const submitLogin = () => {
            loginRequest({
                username: loginUsername.value,
                password: loginPassword.value,
            })
                .then(response => {
                    login(response);
                    return response;
                })
                .catch(() => { loginFailed.value = true; })
                .finally(() => (isLoggingIn.value = false));
        };

        return {
            loginUsername,
            loginPassword,
            loginFailed,
            isLogged,
            isPremium,
            submitLogin,
        };
    },
};
</script>
