import { http } from '@/app/helpers/axios-instance';
import { toPageable, toPagination } from '@/app/helpers/pagination';

export const fetchMembers = ({ gender, ageMin, ageMax, pagination = {} }) =>
  http
    .get('/v1/members', { params: { gender, ageMin, ageMax, ...toPageable(pagination) } })
    .then(response => toPagination(response.data));

export const fetchMember = name =>
  http.get(`/v1/members/${name}`).then(response => response.data)

export const removeFromWishlist = name =>
  http.delete(`/v1/members/wishlist/${name}`)
    .then(response => response.data)

export const addToWishlist = name =>
  http.post(`/v1/members/wishlist/${name}`)
    .then(response => response.data)

export const fetchWishlistMembers = ({ pagination = {} }) =>
  http
    .get('/v1/members/wishlist', { params: { ...toPageable(pagination) } })
    .then(response => toPagination(response.data));

export const fetchMyProfile = () =>
  http.get(`/v1/my-profile`).then(response => response.data)

export const updateMyProfile = (profile) =>
  http.put(`/v1/my-profile`, profile).then(response => response.data)