export const DEFAULT_SORTING_COLUMN = 'id';
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_ROWS_NUMBER = 16;
export const DESCENDING = 'DESC';
export const ASCENDING = 'ASC';

const DEFAULT_PAGINATION = {
  descending: true,
  sortBy: DEFAULT_SORTING_COLUMN,
  page: DEFAULT_PAGE_NUMBER,
  rowsPerPage: DEFAULT_ROWS_NUMBER,
};

export const defaultPagination = () => DEFAULT_PAGINATION;

export const parsePageNumber = (number, pagesCount) =>
  number < 1 ? 1 : number > pagesCount ? pagesCount : number;

export const toPageable = ({
  descending,
  sortBy,
  page,
  rowsPerPage = DEFAULT_ROWS_NUMBER,
} = DEFAULT_PAGINATION) => {
  const sortColumn = sortBy || DEFAULT_SORTING_COLUMN;
  const sortOrder = descending === 'ascend' ? ASCENDING : DESCENDING;

  return {
    page: (page || DEFAULT_PAGE_NUMBER) - 1,
    size: rowsPerPage,
    sort: `${sortColumn},${sortOrder}`,
  };
};

export const toPagination = ({ content, totalElements, totalPages, pageable }) => {
  const pageNumber = (pageable && pageable.pageNumber + 1) || DEFAULT_PAGE_NUMBER;

  return {
    items: content,
    total: totalElements,
    page: pageNumber,
    pagesCount: totalPages,
  };
};
