<template>
    <div class="col-md-3">
        <div id="slidingMenu">

            <ul class="squarebuttons mobile-hide">
                <li :class="{ 'active': currentPath == '/my-profile' }" >
                    <router-link to="/my-profile">
                        <i class="my-profile"></i> My profile
                    </router-link>
                </li>
                <li :class="{ 'active': currentPath.startsWith('/messages') && !currentPath.includes('/flirts') }" >
                    <router-link to="/messages/inbox">
                        <i class="my-mailbox"></i> My mailbox
                    </router-link>
                </li>
                <li :class="{ 'active': currentPath.startsWith('/messages/flirts') }">
                    <router-link to="/messages/flirts/inbox">
                        <i class="my-flirts"></i> My flirts
                    </router-link>
                </li>
            </ul>

            <div class="shadow divmenu">
                <ul class="side-navigation">

                    <li class="mobile-only" :class="{ 'active': currentPath == '/credits' }">
                        <router-link to="/credits">
                            <i class="coins"></i><template v-if="credits == 1">{{ credits }} credit</template><template v-else>{{ credits }} credits</template>
                        </router-link>
                    </li>
                    <li class="mobile-only " :class="{ 'active': currentPath == '/my-profile' }">
                        <router-link to="/my-profile">
                            <i class="my-profile"></i> My profile
                        </router-link>
                    </li>
                    <li :class="{ 'active': currentPath.startsWith('/members') }">
                        <router-link to="/members">
                            <i class="members"></i> Members
                        </router-link>
                    </li>
                    <li class="mobile-only " :class="{ 'active': currentPath.startsWith('/messages') }">
                        <router-link to="/messages/inbox">
                            <i class="my-mailbox"></i> My mailbox
                        </router-link>
                    </li>
                    <li class="mobile-only " :class="{ 'active': currentPath == '/messages/inbox/flirt' }">
                        <router-link to="/messages/flirts/inbox">
                            <i class="my-flirts"></i> My flirts
                        </router-link>
                    </li>
                    <li :class="{ 'active': currentPath == '/wishlist' }">
                        <router-link to="/wishlist">
                            <i class="my-wish-list"></i> My wishlist
                        </router-link>
                    </li>
                    <li class="mobile-hide" :class="{ 'active': currentPath == '/credits' }">
                        <router-link to="/credits">
                            <i class="coins"></i><template v-if="credits == 1">{{ credits }} credit</template><template v-else>{{ credits }} credits</template>
                        </router-link>
                    </li>
                    <li :class="{ 'active': currentPath == '/settings' }">
                        <router-link to="/settings">
                            <i class="settings"></i> Settings
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/" @click="logout">
                            <i class="logout"></i> Logout
                        </router-link>
                    </li>
                </ul>

            </div>

        </div>


    </div><!--/col-md-3-->
</template>
    
<script>
import { useUser } from '@/app/use/user';
import { useUrl } from '@/app/router';

export default {
    name: 'SideMenu',
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
    },
    mounted: function () {
        document.getElementById('jquery-extra-script')?.remove();
        const jqueryExtraPlugin = document.createElement('script');
        jqueryExtraPlugin.src = '/theme/001/js/jquery.extra.min.js';
        jqueryExtraPlugin.id = 'jquery-extra-script';
        jqueryExtraPlugin.async = true;
        document.head.appendChild(jqueryExtraPlugin);
    },
    setup() {
        const { logout, credits } = useUser();
        const { currentPath } = useUrl();

        return { currentPath, credits, logout };
    },
};
</script>
    