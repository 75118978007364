import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import isNil from 'lodash/isNil';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ROWS_NUMBER,
  DEFAULT_SORTING_COLUMN,
} from '@/app/helpers/pagination';

export const useQuery = () => {
  const route = useRoute();
  const router = useRouter();

  const query = computed(() => route.query);
  const search = computed(() => route.query.search);
  const columns = computed(() => route.query.columns);

  const pagination = computed(() => ({
    page: parseInt(query.value.page) || DEFAULT_PAGE_NUMBER,
    rowsPerPage: parseInt(query.value.rowsPerPage) || DEFAULT_ROWS_NUMBER,
    descending: query.value.descending || isNil(query.value.descending),
    sortBy: query.value.sortBy || DEFAULT_SORTING_COLUMN,
  }));

  const update = queryParam => {
    router.replace({ query: { ...route.query, ...queryParam } }).catch(() => {});
  };

  const removeQuery = () => {
    router.replace({ query: null }).catch(() => {});
  };

  return { search, update, query, pagination, columns, removeQuery };
};
