<template>
  <div>
    <div class="navMenu-main">
      <div id="menu" class="gn-icon-menu"><span></span></div>
    </div>
    <Header :instanceInfo="instanceInfo" />
    <MemberSearchBar :instanceInfo="instanceInfo" />
    <div class="main">
      <div class="container">
        <div class="row">
          <SideMenu :instanceInfo="instanceInfo" />
          <div class="col-md-9">
            <h2 class="pink">Members</h2>
            <p>&nbsp;</p>
            <ul class="userlist-member">
              <li v-for="member in members" :key="member.name">
                <router-link :to="'/members/' + member.name">
                  <img :src="'/uploads/150/' + member.image">
                </router-link>
                <p class="username">
                  <a :href="'/members/' + member.name">
                    {{ member.name }}
                  </a>
                </p>
                <p>
                  {{ member.age }} years old
                </p>
                <p>
                </p>
              </li>
            </ul>
            <div class="col-md-12">
              <hr noshade class="pink">
              <div class="pager center">
                <div class="pagination">
                  <ul>
                    <li :class="{ 'disabled': currentPage == 1 }">
                      <router-link v-if="currentPage > 1" :to="'/members?page=' + (currentPage - 1) + getQuery()"
                        rel="prev">&laquo;</router-link>
                      <span v-else>&laquo;</span>
                    </li>
                    <li v-for="n in pagesCount" :key="n" :class="{ 'active': n == currentPage }">
                      <router-link v-if="n != currentPage" :to="'/members?page=' + n + getQuery()">{{ n }}</router-link>
                      <span v-else>{{ n }}</span>
                    </li>
                    <li :class="{ 'disabled': currentPage == pagesCount }">
                      <router-link v-if="currentPage < pagesCount" :to="'/members?page=' + (currentPage + 1) + getQuery()"
                        rel="next">&raquo;</router-link>
                      <span v-else>&raquo;</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div><!--/col-md-9-->
        </div>
      </div>
    </div>
    <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import SideMenu from '@/app/components/SideMenu';
import MemberSearchBar from '@/app/components/MemberSearchBar';
import { fetchMembers } from '@/app/api/member';
import { useQuery } from '@/app/use/router';
import { useRoute } from 'vue-router';

export default {
  name: 'Members',
  components: { Header, Footer, SideMenu, MemberSearchBar },
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    companyName: {
      type: String,
      required: false,
    },
  },
  setup() {
    const route = useRoute();
    const currentQuery = ref(route.query);
    const { pagination } = useQuery();
    const members = ref([]);
    const totalItems = ref(0);
    const pagesCount = ref(1);
    const currentPage = ref(1);

    const loadMembers = () => {
      pagination.value.descending = 'ascend';
      fetchMembers({
        gender: route.query.gender,
        ageMin: route.query.ageMin,
        ageMax: route.query.ageMax,
        pagination: pagination.value,
      })
        .then(async data => {
          members.value = data.items;
          totalItems.value = data.total;
          pagesCount.value = data.pagesCount;
          currentPage.value = data.page;
        });
    };

    loadMembers();

    watch(
      () => route.query,
      async () => {
        loadMembers();
        currentQuery.value = route.query;
      },
      { immediate: true, deep: true }
    );

    const getQuery = () => {
      var query = '';
      const map = new Map(Object.entries(currentQuery.value));
      map.forEach((value, key) => {
        if (key != 'page') {
          query += ('&' + key + '=' + value);
        }
      });
      return query;
    }

    return {
      members,
      totalItems,
      pagesCount,
      currentPage,
      pagination,
      currentQuery,
      getQuery
    };
  },
};
</script>
